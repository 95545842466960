import axios from 'axios';
import { setAxiosInterceptors } from '@lendica/auth';

export const env = 'idev';

export const baseURL =
    env === 'idev' ? 'https://api-dev.golendica.com/api/v1' : 'https://api.golendica.com/api/v1';

export const axiosInstance = axios.create({ baseURL });
axios.defaults.baseURL = baseURL;
setAxiosInterceptors(baseURL);
